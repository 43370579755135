import { callActionOrExecute, DataServiceQuery, IContext, IDataServiceRequest, IQueryResultSettings } from "@msdyn365-commerce/retail-proxy";
import * as Entities from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';
import * as Types from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';

function getProductUnitOfMeasureOptionsQuery(): DataServiceQuery {
    return new DataServiceQuery();
}


export function getProductUnitOfMeasureOptionsInput(
    queryResultSettings: IQueryResultSettings, productIds:
    number[]
): IDataServiceRequest {
    const query = getProductUnitOfMeasureOptionsQuery().resultSettings(queryResultSettings);
    query.top(10000);
    return query.createDataServiceRequestForOperation(
        'GetProductUnitOfMeasureOptions',
        true, Entities.UnitOfMeasureConversionClass,
        'true',
        { bypassCache: 'none', returnEntity: 'Entities.UnitOfMeasureConversion' },
        {
            productIds: productIds
        }
    );
}

export async function getProductUnitOfMeasureOptionsAsync(context: IContext, productIds: number[]): Promise<Types.UnitOfMeasureConversion[]> {
    const request = getProductUnitOfMeasureOptionsInput(
        context.queryResultSettings || {}, productIds);
    const conversions = await callActionOrExecute<Types.UnitOfMeasureConversion[]>(request, context.callerContext);
    return conversions;
}