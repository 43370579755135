import { CommerceProperty } from "@msdyn365-commerce/retail-proxy";
import * as EntityClasses from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';

export interface IRetailConfigurationParameter {
    RecId?: number;
    Name?: string;
    Value?: string;
}

export class RetailConfigurationParameterExtensionClass implements IRetailConfigurationParameter {
    public Name?: string;
    public Value?: string;
    public RecId?: number;

    public ExtensionProperties: CommerceProperty[];

    constructor(odataObject?: any) {
        odataObject = odataObject || {};

        this.Name = odataObject.Name;
        this.Value = odataObject.Value;
        this.RecId = (odataObject.RecId) ? parseInt(odataObject.RecId, 10) : 0;

        // @ts-ignore
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
            this.ExtensionProperties = [];
            for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
                if (odataObject.ExtensionProperties[i]) {
                    if (odataObject.ExtensionProperties[i]['@odata.type']) {
                        var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
                        if (EntityClasses[className]) {
                            this.ExtensionProperties[i] =
                                new EntityClasses[className](odataObject.ExtensionProperties[i]);
                        }
                    } else {
                        this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
                    }

                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.ExtensionProperties[i] = undefined;
                }
            }
        }
    }
}