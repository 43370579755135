import { callActionOrExecute, DataServiceQuery, IContext, IDataServiceRequest, IQueryResultSettings } from "@msdyn365-commerce/retail-proxy";
import * as Entities from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';
import * as Types from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';

function getCachedActivePricesQuery(): DataServiceQuery {
    return new DataServiceQuery();
}

export function getCachedActivePricesInput(
    queryResultSettings: IQueryResultSettings,
    projectDomain: Types.ProjectionDomain,
    productIds: number[],
    activeDate: Date,
    customerId: string | null,
    includeSimpleDiscountsInContextualPrice: boolean,
    includeVariantPriceRange: boolean,
    includeAttainablePricesAndDiscounts: boolean): IDataServiceRequest {
    const query = getCachedActivePricesQuery().resultSettings(queryResultSettings);
    return query.createDataServiceRequestForOperation(
        'GetCachedActivePrices',
        true,
        Entities.ProductPriceClass,
        'true',
        { bypassCache: 'get', returnEntity: 'Entities.ProductPriceClass' },
        {
            projectDomain: projectDomain,
            productIds: productIds,
            activeDate: activeDate,
            customerId: customerId,
            includeSimpleDiscountsInContextualPrice: includeSimpleDiscountsInContextualPrice,
            includeVariantPriceRange: includeVariantPriceRange,
            includeAttainablePricesAndDiscounts: includeAttainablePricesAndDiscounts
        }
    );
}

/**
 * @deprecated Price caching is no longer in use.
 */
export async function getCachedActivePricesAsync(
    context: IContext,
    projectDomain: Types.ProjectionDomain,
    productIds: number[],
    activeDate: Date,
    customerId: string | null,
    includeSimpleDiscountsInContextualPrice: boolean,
    includeVariantPriceRange: boolean,
    includeAttainablePricesAndDiscounts: boolean): Promise<Types.ProductPrice[]> {
    const request = getCachedActivePricesInput(
        context.queryResultSettings || {},
        projectDomain,
        productIds,
        activeDate,
        customerId,
        includeSimpleDiscountsInContextualPrice,
        includeVariantPriceRange,
        includeAttainablePricesAndDiscounts);
    const result = await callActionOrExecute<Types.ProductPrice[]>(request, context.callerContext);
    return result;
}