import { AsyncResult, DataServiceQuery, IContext, IDataServiceRequest, IQueryResultSettings, callActionOrExecute } from "@msdyn365-commerce/retail-proxy";
import { SimpleProductClass } from "@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g";
import { SimpleProduct } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';

export function getCountingListProductsInput(
    queryResultSettings: IQueryResultSettings,
    channelId: number,
    productIds: number[],
    inventLocationId: string | null | undefined,
    catalogId: number
): IDataServiceRequest {
    const query = new DataServiceQuery();
    query.resultSettings(queryResultSettings);
    return query.createDataServiceRequestForOperation(
        'GetCountingListProducts',
        true, SimpleProductClass,
        'true',
        { bypassCache: 'none', returnEntity: 'SimpleProduct' },
        {
            channelId: channelId,
            inventLocationId: inventLocationId,
            productIds: productIds,
            catalogId: catalogId
        }
    );
}

export function getCountingListProductsAsync(
    context: IContext,
    channelId: number,
    productIds: number[],
    inventLocationId: string | null | undefined,
    catalogId: number
): AsyncResult<SimpleProduct[]> {
    const request = getCountingListProductsInput(
        context.queryResultSettings || {}, channelId, productIds, inventLocationId, catalogId);
    return callActionOrExecute<SimpleProduct[]>(request, context.callerContext);
}