import * as EntityClasses from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';

/**
 * AlcoholLicenseValidationDetails entity interface.
 */
export interface IAlcoholLicenseValidationDetails {
    Message?: string;
    LicenseNeeded?: number;
    ProductId?: number;
}

/**
 * AlcoholValidationCriteria entity interface.
 */
export interface IAlcoholValidationCriteria {
    ProductIds: number[];
    CatalogId: number;
}

/**
 * ValidateAlcoholLicenseResult entity interface.
 */
export interface IValidateAlcoholLicenseResult {
    ValidationResult?: boolean;
    CustomerLicense?: number;
    Details?: IAlcoholLicenseValidationDetails[];
}

/**
 * AlcoholValidationCriteria entity class.
 */
export class AlcoholValidationCriteriaExtensionClass implements IAlcoholValidationCriteria {

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ProductIds: number[];

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public CatalogId: number;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.CatalogId = odataObject.CatalogId;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ProductIds = undefined;
        if (odataObject.ProductIds) {
            this.ProductIds = [];
            for (var i = 0; i < odataObject.ProductIds.length; i++) {
                if (odataObject.ProductIds[i]) {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.ProductIds[i] =
                        parseInt(odataObject.ProductIds[i], 10);

                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.ProductIds[i] = undefined;
                }
            }
        }

    }
}

/**
 * AlcoholLicenseValidationDetails entity class.
 */
export class AlcoholLicenseValidationDetailsExtensionClass implements IAlcoholLicenseValidationDetails {

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Message: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public LicenseNeeded: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ProductId: number;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Message = odataObject.Message;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.LicenseNeeded = odataObject.LicenseNeeded;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ProductId = (odataObject.ProductId) ? parseInt(odataObject.ProductId, 10) : 0;
    }
}

/**
 * ValidateAlcoholLicenseResult entity class.
 */
export class ValidateAlcoholLicenseResultExtensionClass implements IValidateAlcoholLicenseResult {

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ValidationResult: boolean;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public CustomerLicense: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Details: IAlcoholLicenseValidationDetails[];

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ValidationResult = odataObject.ValidationResult;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.CustomerLicense = odataObject.CustomerLicense;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Details = undefined;
        if (odataObject.Details) {
            this.Details = [];
            for (var i = 0; i < odataObject.Details.length; i++) {
                if (odataObject.Details[i]) {
                    if (odataObject.Details[i]['@odata.type']) {
                        var className: string = odataObject.Details[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
                        if (EntityClasses[className]) {
                            this.Details[i] = new EntityClasses[className](odataObject.Details[i]);
                        }
                    } else {
                        this.Details[i] = new AlcoholLicenseValidationDetailsExtensionClass(odataObject.Details[i]);
                    }

                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.Details[i] = undefined;
                }
            }
        }

    }
}