import { AsyncResult, Cart, DataServiceQuery, IContext, IDataServiceRequest, callActionOrExecute } from '@msdyn365-commerce/retail-proxy';
import * as Entities from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';

function updateCalcCartLinesQuery(): DataServiceQuery {
    return new DataServiceQuery();
}

/**
 * An enumeration used to indicate which combination of calculations should be
 * performed when returning the cart.
 */
export enum CalculationModes {
    /**
     * No calculations are performed.
     * This value must be checked directly and not used with HasFlags method.
     */
    None = 0,
    /**
     * Prices are calculated.
     */
    Prices = 1,
    /**
     * Discounts are calculated.
     */
    Discounts = 2,
    /**
     * Charges are calculated.
     */
    Charges = 4,
    /**
     * Taxes are calculated.
     */
    Taxes = 8,
    /**
     * Totals are calculated and rounded.
     */
    Totals = 16, // 0x00000010
    /**
     * Deposit and prepayments are calculated.
     */
    Deposit = 32, // 0x00000020
    /**
     * Amount due (balance due) is calculated.
     */
    AmountDue = 64, // 0x00000040
    /**
     * All calculations are performed.
     */
    All = 127 // 0x0000007F
}

export function updateCalcCartLinesInput(
    id: string,
    cartLines: Entities.CartLine[],
    calculationModes: CalculationModes,
    cartVersion: number | null
): IDataServiceRequest {
    const query = updateCalcCartLinesQuery();
    return query.createDataServiceRequestForOperation(
        'UpdateCalcCartLines',
        true,
        'CartClass',
        'false',
        { bypassCache: 'get', returnEntity: 'Cart' },
        {
            id: id,
            cartLines: cartLines,
            calculationModes: calculationModes,
            cartVersion: cartVersion
        }
    );
}

export function updateCalcCartLinesAsync(
    context: IContext,
    id: string,
    cartLines: Entities.CartLine[],
    calculationModes: CalculationModes,
    cartVersion: number | null
): AsyncResult<Cart> {
    const request = updateCalcCartLinesInput(id, cartLines, calculationModes, cartVersion);
    return callActionOrExecute<Cart>(request, context.callerContext);
}
