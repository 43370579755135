import { IActionContext } from '@msdyn365-commerce/core';
import { DataServiceQuery, IDataServiceRequest, callActionOrExecute } from "@msdyn365-commerce/retail-proxy";
import { ValidateAlcoholLicenseResultExtensionClass, IValidateAlcoholLicenseResult, IAlcoholValidationCriteria } from './entities/validate-alcohol-license';

function validateAlcoholLicenseQuery(): DataServiceQuery {
    return new DataServiceQuery();
}

export function validateAlcoholLicenseInput(validationCriteria: IAlcoholValidationCriteria): IDataServiceRequest {
    const query = validateAlcoholLicenseQuery();
    return query.createDataServiceRequestForOperation(
        'ValidateAlcoholLicense',
        true,
        ValidateAlcoholLicenseResultExtensionClass,
        'false',
        { bypassCache: 'get', returnEntity: 'IValidateAlcoholLicenseResult' },
        {
            validationCriteria: validationCriteria
        }
    );
}

export async function validateAlcoholLicenseAsync(ctx: IActionContext, validationCriteria: IAlcoholValidationCriteria): Promise<IValidateAlcoholLicenseResult> {
    const request = validateAlcoholLicenseInput(validationCriteria);
    const result = await callActionOrExecute<IValidateAlcoholLicenseResult>(request, ctx);
    return result;
}
