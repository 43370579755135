import { IActionContext, createObservableDataAction, IAction, ICommerceApiSettings, IGeneric, IAny, ICreateActionContext } from "@msdyn365-commerce/core";
import { CacheType, callActionOrExecute, DataServiceQuery, IActionInput, IDataServiceRequest } from "@msdyn365-commerce/retail-proxy";
import { RetailConfigurationParameterExtensionClass, IRetailConfigurationParameter } from "./entities/retail-configuration-parameter";

export class GetPickupDlvModeInput implements IActionInput {
    private readonly query: DataServiceQuery;

    constructor(apiSettings: ICommerceApiSettings) {
        this.query = new DataServiceQuery();
    }

    public getDataServiceRequest = (): IDataServiceRequest => {
        return this.query.createDataServiceRequestForOperation(
            'GetPickupDlvMode',
            false,
            RetailConfigurationParameterExtensionClass,
            'false',
            { bypassCache: 'get', returnEntity: 'RetailConfigurationParameterExtensionClass' },
            {}
        );
    };
    public shouldCacheOutput = (): boolean => false;

    public getCacheKey = () => `PickupDlvMode`;
    public getCacheObjectType = () => 'PickupDlvMode';
    public dataCacheType = (): CacheType => 'request';
}

const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>): GetPickupDlvModeInput => {
    return new GetPickupDlvModeInput(inputData.requestContext.apiSettings);
};

export const getPickupDlvModeAction = async (input: any, ctx: IActionContext): Promise<IRetailConfigurationParameter> => {
    const result = await callActionOrExecute<IRetailConfigurationParameter>(input.getDataServiceRequest(), ctx);
    return result;
};

export const getPickupDlvMode = createObservableDataAction({
    id: 'src/actions/get-pickup-dlv-mode',
    action: <IAction<IRetailConfigurationParameter>>getPickupDlvModeAction,
    input: createInput
});

export default getPickupDlvMode;