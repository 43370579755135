import { createObservableDataAction, IAction, IActionContext } from '@msdyn365-commerce/core';
import {
    AsyncResult,
    CacheType,
    DataServiceQuery,
    IActionInput,
    IContext,
    IDataServiceRequest,
    IQueryResultSettings,
    callActionOrExecute
} from '@msdyn365-commerce/retail-proxy';
import * as Entities from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import * as EntityClasses from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';

export class GetCountingListsInput implements IActionInput {
    public shouldCacheOutput = (): boolean => false;

    public getCacheKey = () => 'get-counting-lists';
    public getCacheObjectType = () => 'counting-lists';
    public dataCacheType = (): CacheType => 'request';
}

const createInput = (): GetCountingListsInput => {
    return new GetCountingListsInput();
};

export function createGetCountingListsInput(
    queryResultSettings: IQueryResultSettings,
    customerId: string,
    countingListId: string
): IDataServiceRequest {
    const query = new DataServiceQuery('DlvryProductLists', 'DlvryProductList');
    return query.createDataServiceRequestForOperation(
        'GetCountingLists',
        true,
        EntityClasses.ProductListClass,
        'true',
        {
            bypassCache: 'none',
            returnEntity: 'Entities.ProductList'
        },
        {
            customerId,
            countingListId
        }
    );
}

export function getCountingListsAsync(context: IContext, customerId: string, countingListId?: string): AsyncResult<Entities.ProductList[]> {
    const request = createGetCountingListsInput(context.queryResultSettings || {}, customerId, countingListId || '');
    return callActionOrExecute<Entities.ProductList[]>(request, context.callerContext);
}

export async function getCountingListsAsyncAction(input: GetCountingListsInput, context: IActionContext): Promise<Entities.ProductList[]> {
    if (!context.requestContext.user.isAuthenticated) {
        throw new Error('Unable to get counting lists. User is not authenticated.');
    }

    const countingLists = await getCountingListsAsync(
        { callerContext: context },
        context.requestContext.user.customerAccountNumber || '',
        ''
    );

    return countingLists;
}

export const getCountingLists = createObservableDataAction({
    id: 'dlvry/get-counting-lists',
    action: <IAction<Entities.ProductList[]>>getCountingListsAsyncAction,
    input: createInput
});

export default getCountingLists;
