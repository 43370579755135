import { callActionOrExecute, DataServiceQuery, IContext, IDataServiceRequest } from "@msdyn365-commerce/retail-proxy";
import { ExtendedCustomerExtensionClass, IExtendedCustomer } from "./entities/extended-customer";

function getExtendedCustomerQuery(): DataServiceQuery {
    return new DataServiceQuery();
}

export function getExtendedCustomerInput(): IDataServiceRequest {
    const query = getExtendedCustomerQuery();
    return query.createDataServiceRequestForOperation(
        'GetExtendedCustomer',
        false,
        ExtendedCustomerExtensionClass,
        'false',
        { bypassCache: 'get', returnEntity: 'IExtendedCustomer' },
        { }
    );
}

export async function getExtendedCustomerAsync(context: IContext): Promise<IExtendedCustomer> {
    const request = getExtendedCustomerInput();
    const result = await callActionOrExecute<IExtendedCustomer>(request, context.callerContext);
    return result;
}