import { AsyncResult, callActionOrExecute, DataServiceQuery, IContext, IDataServiceRequest } from "@msdyn365-commerce/retail-proxy";

function getUpcomingOrderEntryDeadlineQuery(): DataServiceQuery {
    return new DataServiceQuery();
}

export function getUpcomingOrderEntryDeadlineInput(callerTime: Date): IDataServiceRequest {
    const query = getUpcomingOrderEntryDeadlineQuery();
    return query.createDataServiceRequestForOperation(
        'GetUpcomingOrderEntryDeadline',
        true,
        ''
        ,
        'false',
        { bypassCache: 'get', returnEntity: '' },
        {
            callerTime: callerTime
        }
    );
}

export function getUpcomingOrderEntryDeadlineAsync(context: IContext, callerTime: Date): AsyncResult<Date> {
    const request = getUpcomingOrderEntryDeadlineInput(callerTime);
    return callActionOrExecute<Date>(request, context.callerContext);
}