import { IActionContext } from "@msdyn365-commerce/core";
import { callActionOrExecute, DataServiceQuery, IDataServiceRequest } from "@msdyn365-commerce/retail-proxy";
import { BrandSchemeEntityExtensionClass, IBrandSchemeEntity } from "./entities/brand-scheme-entity";

// @ts-ignore
function brandSchemeQuery(brandSchemeId?: string): DataServiceQuery {
    const key = (brandSchemeId) ? { BrandSchemeId: brandSchemeId } : null;
    return new DataServiceQuery("BrandScheme", "BrandSchemeEntity", BrandSchemeEntityExtensionClass, key);
}

export function createGetBrandSchemeByIdInput(brandSchemeId: string): IDataServiceRequest {
    const query = brandSchemeQuery();
    return query.createDataServiceRequestForOperation('GetBrandSchemeById', false, BrandSchemeEntityExtensionClass, 'false', { bypassCache: 'none', returnEntity: 'DataServiceEntities.IBrandSchemeEntity' }, { brandSchemeId: brandSchemeId });
}

export async function getBrandSchemeByIdAsync(context: IActionContext, brandSchemeId: string): Promise<IBrandSchemeEntity> {
    const request = createGetBrandSchemeByIdInput(brandSchemeId);
    const result = await callActionOrExecute<IBrandSchemeEntity>(request, context);
    return result;
}
