import * as Entities from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import * as EntityClasses from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';
import { AsyncResult, DataServiceQuery, IContext, IDataServiceRequest, callActionOrExecute } from '@msdyn365-commerce/retail-proxy';

export function createAddCountingListLineInput(productListId: string, countingListLine: Entities.ProductListLine): IDataServiceRequest {
    const query = new DataServiceQuery("DlvryProductLists", "DlvryProductList");
    return query.createDataServiceRequestForOperation('AddCountingListLine', true, EntityClasses.ProductListLineClass, 'false', { bypassCache: 'get', returnEntity: 'Entities.ProductListLine' }, { productListId: productListId, countingListLine: countingListLine });
}

export function addCountingListLineAsync(context: IContext, productListId: string, countingListLine: Entities.ProductListLine): AsyncResult<Entities.ProductListLine> {
    const request = createAddCountingListLineInput(productListId, countingListLine);
    return callActionOrExecute<Entities.ProductListLine>(request, context.callerContext);
}