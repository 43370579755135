import { IActionContext } from '@msdyn365-commerce/core';
import { AttributeDataType, CustomerAttribute, ProductRefinerSource, ProductRefinerValue, ProductSearchCriteria } from '@msdyn365-commerce/retail-proxy';
// @ts-ignore
import { getCustomer, GetCustomerInput } from '@msdyn365-commerce-modules/retail-actions';
import getOrganizationCustomer, { GetOrganizationCustomerInput } from '../../../actions/get-organization-customer';

// Pre populated with ids from STAGE for local development
const AlcoholGroupAttributeId = 5637155076;

function isGodmodeEnabled(context: IActionContext): boolean {
    const godmode = context.requestContext.url.requestUrl.searchParams.get('godmode')?.toLowerCase();

    return godmode === '1' || godmode === 'true';
}

export function shouldFilterAlcohol(context: IActionContext): boolean {
    const doAlcoholFiltering = context.requestContext.url.requestUrl.searchParams.get('alcohol')?.toLowerCase() !== 'false';
    const alcoholRefinerRecordId = Number(context.requestContext.app.config?.alcoholRefinerRecordId);

    return doAlcoholFiltering && !isNaN(alcoholRefinerRecordId) && alcoholRefinerRecordId > 0;
}

export async function getAlcoholRefiners(context: IActionContext): Promise<ProductRefinerValue[]> {
    let alcoholGroupAttributeId = Number(context.requestContext.app.config?.alcoholRefinerRecordId);

    if (isNaN(alcoholGroupAttributeId) || alcoholGroupAttributeId === 0) {
        alcoholGroupAttributeId = AlcoholGroupAttributeId;
    }

    const result: ProductRefinerValue[] = [];
    const alcoholRefiner: ProductRefinerValue = {
        DataTypeValue: 5,
        LeftValueBoundString: '0',
        RefinerRecordId: alcoholGroupAttributeId,
        RefinerSourceValue: 1,
        RightValueBoundString: '0',
        UnitText: ''
    };

    result.push(alcoholRefiner);

    try {
        let alcoholLicenseValue = 0;

        if (context.requestContext.user.isAuthenticated) {
            // Get Customer attributes
            // const customer = await getCustomer(new GetCustomerInput(context.requestContext.apiSettings, context.requestContext.user.customerAccountNumber), context);
            const customer = await getOrganizationCustomer(new GetOrganizationCustomerInput(context.requestContext.apiSettings, context.requestContext.user.customerAccountNumber!), context);
            const alcoholLicense = Number(customer.Attributes?.find(x => x.RecordId === alcoholGroupAttributeId)?.AttributeValue?.StringValue);

            if (isNaN(alcoholLicense)) {
                // Throw NaN error
                console.warn('Alcohol license value is missing or not a number.');
            } else {
                alcoholLicenseValue = alcoholLicense;
            }
        } else {
            // If user is not logged in show all products
            alcoholLicenseValue = 3;
        }

        // TODO: Verify we can do ranges like this on text attributes
        // If we can do range on text attributes use this:
        //alcoholRefiner.RightValueBoundString = alcoholLicense?.AttributeValue?.StringValue ?? '0';

        // Add a refiner for each alcohol group
        Array(alcoholLicenseValue).fill(0).forEach((_, index) => {
            result.push({
                ...alcoholRefiner,
                LeftValueBoundString: (index + 1).toString(),
                RightValueBoundString: (index + 1).toString()
            });
        });
    } catch (error) {
        console.warn('Failed to get customer attributes for alcohol license or parse value of attribute. No alcohol license applied to search.', error);
    }

    return result;
}

export async function addAlcoholRefiner(context: IActionContext, searchCriteriaInput: ProductSearchCriteria): Promise<void> {
    let alcoholGroupAttributeId = Number(context.requestContext.app.config?.alcoholRefinerRecordId);

    if (isNaN(alcoholGroupAttributeId) || alcoholGroupAttributeId === 0) {
        alcoholGroupAttributeId = AlcoholGroupAttributeId;
    }

    if (searchCriteriaInput.Refinement!.some(x => x.RefinerRecordId === alcoholGroupAttributeId)) {
        // Remove existing, shouldn't be here
        searchCriteriaInput.Refinement = searchCriteriaInput.Refinement!.filter(x => x.RefinerRecordId !== alcoholGroupAttributeId);
    }

    const refiners = await getAlcoholRefiners(context);

    searchCriteriaInput.Refinement = searchCriteriaInput.Refinement!.concat(refiners);

    return Promise.resolve();
}

export function addOrUpdateZeroPriceRefiner(context: IActionContext, refiners?: ProductRefinerValue[]): ProductRefinerValue[] {
    // Check criteria for allowing user to see zero price products. Example:
    // - Has ?godmode=true in url
    const allowZeroPriceProducts = isGodmodeEnabled(context);

    if (!allowZeroPriceProducts) {
        const result = refiners ? [...refiners] : [];

        // Make sure we don't show zero price products
        const priceRefiner = result.find(x => x.RefinerRecordId === 0);

        if (priceRefiner) {
            // There is a price refiner, make sure it has a min value of 0.01 or more
            if (!priceRefiner.LeftValueBoundString || Number(priceRefiner.LeftValueBoundString) < 0.01) {
                priceRefiner.LeftValueBoundString = '0.01';
            }
        } else {
            // No price refiner, add one
            result.push({
                '@odata.type': '#Microsoft.Dynamics.Commerce.Runtime.DataModel.ProductRefinerValue',
                Count: 0,
                DataTypeValue: 1,
                LeftValueBoundString: '0.01',
                ExtensionProperties: [],
                RefinerRecordId: 0,
                RefinerSourceValue: 3,
                RightValueBoundString: '1000000000',
                RowNumber: 0,
                UnitText: context.requestContext.channel?.Currency
            } as any);
        }

        return result;
    }

    return refiners || [];
}

export async function getAttributeRefiners(context: IActionContext, customerAttributes: CustomerAttribute[] | undefined, attributeRecordId: number, friendlyName?: string): Promise<ProductRefinerValue[]> {
    const result: ProductRefinerValue[] = [];
    const refiner: ProductRefinerValue = {
        DataTypeValue: 5,
        LeftValueBoundString: '0',
        RefinerRecordId: attributeRecordId,
        RefinerSourceValue: 1,
        RightValueBoundString: '0',
        UnitText: ''
    };

    result.push(refiner);

    try {
        let attributeValue = 0;

        if (context.requestContext.user.isAuthenticated) {
            const attribute = Number(customerAttributes?.find(x => x.RecordId === attributeRecordId)?.AttributeValue?.StringValue);

            if (isNaN(attribute)) {
                // Throw NaN error
                if (friendlyName) {
                    console.warn(`${friendlyName} attribute value is missing or not a number.`);
                } else {
                    console.warn(`Attribute with record id ${attributeRecordId} is missing a value or not a number.`);
                }
            } else {
                attributeValue = attribute;
            }
        } else {
            // If user is not logged in show all products
            attributeValue = 1;
        }

        if (attributeValue !== 0) {
            result.push({
                ...refiner,
                LeftValueBoundString: attributeValue.toString(),
                RightValueBoundString: attributeValue.toString()
            });
        }
    } catch (error) {
        if (friendlyName) {
            console.warn(`Failed to get ${friendlyName} customer attribute or parse value of attribute. No filter applied to search.`, error);
        } else {
            console.warn(`Failed to get customer attribute (record id: ${attributeRecordId}) or parse value of attribute. No filter applied to search.`, error);
        }
    }

    return result;
}

export async function getForsvaretRefiner(context: IActionContext, customerAttributes: CustomerAttribute[] | undefined): Promise<ProductRefinerValue | undefined> {
    const forsvaretAttributeId = context.requestContext.app.config?.forsvaretAttributeRecordId;

    if (!forsvaretAttributeId || !context.requestContext.user.isAuthenticated) {
        return;
    }

    try {
        const attribute = customerAttributes?.find(x => x.RecordId === Number(forsvaretAttributeId))?.AttributeValue?.IntegerValue;

        if (!attribute || isNaN(attribute) || attribute === 0) {
            return;
        }

        return {
            DataTypeValue: AttributeDataType.Integer,
            LeftValueBoundString: attribute.toString(),
            RefinerRecordId: Number(forsvaretAttributeId),
            RefinerSourceValue: ProductRefinerSource.Attribute,
            RightValueBoundString: attribute.toString(),
            UnitText: ''
        };
    } catch (error) {
        console.error('Failed to get forsvaret attribute', error);
        return;
    }
}

export async function getSvalbardRefiner(context: IActionContext, customerAttributes: CustomerAttribute[] | undefined): Promise<ProductRefinerValue | undefined> {
    if (!context.requestContext.user.isAuthenticated) {
        return;
    }

    const svalbardAttributeId = context.requestContext.app.config?.svalbardAttributeRecordId;
    const blockedSvalbardAttributeId = context.requestContext.app.config?.blockedSvalbardAttributeRecordId;

    // Both config needs to be set to something
    if (!svalbardAttributeId || !blockedSvalbardAttributeId) {
        return;
    }

    // Both config needs to be numbers. This allows users to prepend values with a character to temporarily disable the refiner
    if (isNaN(svalbardAttributeId) || isNaN(blockedSvalbardAttributeId)) {
        return;
    }

    try {
        const attribute = customerAttributes?.find(x => x.RecordId === Number(svalbardAttributeId))?.AttributeValue?.IntegerValue;

        // Check if attribute is set, is a number and above 0
        if (!attribute || isNaN(attribute) || attribute <= 0) {
            return;
        }

        // Add refiner so that only products that isn't blocked for svalbard is shown since customer attribute is set to 1 or more
        return {
            DataTypeValue: AttributeDataType.Integer,
            LeftValueBoundString: '0',
            RefinerRecordId: Number(blockedSvalbardAttributeId),
            RefinerSourceValue: ProductRefinerSource.Attribute,
            RightValueBoundString: '0',
            UnitText: ''
        };
    } catch (error) {
        console.error('Failed to get svalbard attribute(s)', error);
        return;
    }
}

export async function addAttributeRefiner(context: IActionContext, customerAttributes: CustomerAttribute[] | undefined, searchCriteriaInput: ProductSearchCriteria, attributeRecordId: number): Promise<void> {
    if (searchCriteriaInput.Refinement!.some(x => x.RefinerRecordId === attributeRecordId)) {
        // Remove existing, shouldn't be here
        searchCriteriaInput.Refinement = searchCriteriaInput.Refinement!.filter(x => x.RefinerRecordId !== attributeRecordId);
    }

    const refiners = await getAttributeRefiners(context, customerAttributes, attributeRecordId);

    searchCriteriaInput.Refinement = searchCriteriaInput.Refinement!.concat(refiners);
}

export async function addAttributeRefinerKeepExisting(context: IActionContext, customerAttributes: CustomerAttribute[] | undefined, searchCriteriaInput: ProductSearchCriteria, attributeRecordId: number): Promise<void> {
    if (searchCriteriaInput.Refinement!.some(x => x.RefinerRecordId === attributeRecordId)) {
        return;
    }

    const refiners = await getAttributeRefiners(context, customerAttributes, attributeRecordId);

    searchCriteriaInput.Refinement = searchCriteriaInput.Refinement!.concat(refiners);
}

export async function addAllAttributeRefiners(context: IActionContext, searchCriteriaInput: ProductSearchCriteria): Promise<void> {
    const godmode = isGodmodeEnabled(context);

    if (godmode) {
        return;
    }

    let customerAttributes: CustomerAttribute[] | undefined = [];

    if (context.requestContext.user.isAuthenticated) {
        const { apiSettings, user: { customerAccountNumber } } = context.requestContext;
        const customer = await getOrganizationCustomer(new GetOrganizationCustomerInput(apiSettings, customerAccountNumber!), context);
        customerAttributes = customer.Attributes;
    }

    const attributeRecordIds: string[] = [
        context.requestContext.app.config?.tobaccoRefinerRecordId,
        context.requestContext.app.config?.cocacolaRefinerRecordId,
        context.requestContext.app.config?.bamaRefinerRecordId,
        context.requestContext.app.config?.dutyFreeSugarRefinerRecordId,
        context.requestContext.app.config?.norturaRefinerRecordId
    ];

    for (const attributeRecordId of attributeRecordIds) {
        const id = Number(attributeRecordId);

        // Make sure it's a number and above 0
        if (!isNaN(id) && id > 0) {
            await addAttributeRefiner(context, customerAttributes, searchCriteriaInput, id);
        }
    }

    const transitRefiner = Number(context.requestContext.app.config?.transitRefinerRecordId);

    if (!isNaN(transitRefiner) && transitRefiner > 0) {
        await addAttributeRefinerKeepExisting(context, customerAttributes, searchCriteriaInput, transitRefiner);
    }

    const forsvaretRefiner = await getForsvaretRefiner(context, customerAttributes);

    if (forsvaretRefiner) {
        searchCriteriaInput.Refinement!.push(forsvaretRefiner);
    }

    const svalbardRefiner = await getSvalbardRefiner(context, customerAttributes);

    if (svalbardRefiner) {
        searchCriteriaInput.Refinement!.push(svalbardRefiner);
    }
}

export async function getAllAttributeRefiners(context: IActionContext) {
    const godmode = isGodmodeEnabled(context);

    if (godmode) {
        return [];
    }

    let customerAttributes: CustomerAttribute[] | undefined = [];

    if (context.requestContext.user.isAuthenticated) {
        const { apiSettings, user: { customerAccountNumber } } = context.requestContext;
        const customer = await getOrganizationCustomer(new GetOrganizationCustomerInput(apiSettings, customerAccountNumber!), context);
        customerAttributes = customer.Attributes;
    }

    const attributeRecordIds: string[] = [
        context.requestContext.app.config?.transitRefinerRecordId,
        context.requestContext.app.config?.tobaccoRefinerRecordId,
        context.requestContext.app.config?.cocacolaRefinerRecordId,
        context.requestContext.app.config?.bamaRefinerRecordId,
        context.requestContext.app.config?.dutyFreeSugarRefinerRecordId,
        context.requestContext.app.config?.norturaRefinerRecordId
    ];

    const result: ProductRefinerValue[] = [];

    for (const attributeRecordId of attributeRecordIds) {
        const id = Number(attributeRecordId);

        // Make sure it's a number and above 0
        if (!isNaN(id) && id > 0) {
            const refiners = await getAttributeRefiners(context, customerAttributes, id);
            result.push(...refiners);
        }
    }

    const forsvaretRefiner = await getForsvaretRefiner(context, customerAttributes);

    if (forsvaretRefiner) {
        result.push(forsvaretRefiner);
    }

    const svalbardRefiner = await getSvalbardRefiner(context, customerAttributes);

    if (svalbardRefiner) {
        result.push(svalbardRefiner);
    }

    return result;
}