import { createObservableDataAction, IActionContext, IAction, ICreateActionContext, IGeneric, IAny, ICommerceApiSettings } from '@msdyn365-commerce/core';
import { CacheType, callActionOrExecute, Customer, DataServiceQuery, IActionInput, IDataServiceRequest } from "@msdyn365-commerce/retail-proxy";
import * as Entities from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';

export class GetOrganizationCustomerInput implements IActionInput {
    private readonly user: string | undefined;
    private readonly query: DataServiceQuery;

    constructor(apiSettings: ICommerceApiSettings, user: string) {
        this.user = user;

        this.query = new DataServiceQuery();
    }

    public getDataServiceRequest = (): IDataServiceRequest => {
        return this.query.createDataServiceRequestForOperation(
            'GetOrganizationCustomer',
            false,
            Entities.CustomerClass,
            'false',
            {
                bypassCache: 'get',
                returnEntity: 'Entities.CustomerClass'
            },
            {}
        );
    };

    public shouldCacheOutput = (): boolean => false;

    public getCacheKey = () => `OrganizationCustomer-${this.user}`;
    public getCacheObjectType = () => 'OrganizationCustomer';
    public dataCacheType = (): CacheType => 'request';
}

const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>): GetOrganizationCustomerInput => {
    const { user } = inputData.requestContext;
    return new GetOrganizationCustomerInput(inputData.requestContext.apiSettings, user.customerAccountNumber ?? "");
};

export const getOrganizationCustomerAction = async (input: GetOrganizationCustomerInput, ctx: IActionContext): Promise<Customer> => {
    if (!ctx.requestContext.user.isAuthenticated) {
        return <Customer>{};
    }
    var customer = await callActionOrExecute<Customer>(input.getDataServiceRequest(), ctx);
    return customer;
};

export const getOrganizationCustomer = createObservableDataAction({
    id: 'src/actions/get-organization-customer',
    action: <IAction<Customer>>getOrganizationCustomerAction,
    input: createInput
});

export default getOrganizationCustomer;
