import * as Entities from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import * as EntityClasses from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';
import { AsyncResult, DataServiceQuery, IContext, IDataServiceRequest, callActionOrExecute } from '@msdyn365-commerce/retail-proxy';

export function createUpdateCountingListInput(productList: Entities.ProductList): IDataServiceRequest {
    const query = new DataServiceQuery("DlvryProductLists", "DlvryProductList");
    return query.createDataServiceRequestForOperation('UpdateCountingList', true, EntityClasses.ProductListClass, 'false', { bypassCache: 'get', returnEntity: 'Entities.ProductList' }, { productList: productList });
}


export function updateCountingListAsync(context: IContext, productList: Entities.ProductList): AsyncResult<Entities.ProductList> {
    const request = createUpdateCountingListInput(productList);
    return callActionOrExecute<Entities.ProductList>(request, context.callerContext);
}